@import url(https://fonts.googleapis.com/css?family=Amatic+SC:400,700|Inconsolata:400,700);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, "Inconsolata", monospace, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --text-color: #222;
  --text-color2: #634b66;
  --background-color: #f8f5f5;
  --link-color: #634b66;
  --link-hover-color: #efefef;
  font-size: 1em;
  line-height: 1.5em;
  width: 100vw;
  height: 100vh;
  color: var(--text-color);
  background-color: var(--background-color);
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

@media (prefers-color-scheme: dark) {
  body {
    --background-color: #282c35;
    --text-color: #fff;
    --text-color2: #a28f9d;
    --link-color: #a28f9d;
    --link-hover-color: #efefef;
    background-color: var(--background-color);
  }
}

body.dark {
  --background-color: #282c35;
  --text-color: #fff;
  --text-color2: #a28f9d;
  --link-color: #a28f9d;
  --link-hover-color: #efefef;
  background-color: var(--background-color);
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

::-moz-selection {
  background: #e6e6fa;
}

::selection {
  background: #e6e6fa;
}

h1 {
  font-family: "Amatic SC", cursive, sans-serif;
  font-weight: 700;
  color: var(--text-color2);
  margin: 1rem 0;
  font-size: 3rem;
}

a,
a:active {
  display: inline-block;
  position: relative;
  text-decoration: underline;
  -webkit-text-decoration-skip-ink: ink;
          text-decoration-skip-ink: ink;
  opacity: 1;
  color: var(--link-color);
  outline: none;
}

a:hover,
a:focus {
  text-decoration: none;
  color: var(--link-hover-color);
  outline: none;
  cursor: pointer;
}

a:after {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--link-color);
  z-index: -1;
  -webkit-transition: all 0.1s cubic-bezier(0, 0.59, 1, 0.26);
  transition: all 0.1s cubic-bezier(0, 0.59, 1, 0.26);
}

a:hover:after,
a:focus:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--link-color);
  z-index: -1;
}

.footer {
  border-top: 2px solid #a28f9d;
  text-align: left;
  font-size: 0.8em;
  padding: 1rem 0;
  width: 100%;
  color: var(--text-color);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 3em 0 4em 0;
}

.footer__social {
  padding: 0;
  list-style: none;
  font-weight: 400;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.footer__social__link {
  margin-right: 2em;
}

.footer__social__link:last-child {
  margin-right: 0;
}

.footer__social__link a {
  width: 24px;
  height: 24px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.footer__social__link svg {
  fill: var(--text-color2);
}

.footer__social__link a:hover svg,
.footer__social__link a:focus svg {
  fill: var(--link-hover-color);
}

.footer .made-with {
  font-family: "Amatic SC", cursive, sans-serif;
  font-size: 1.5em;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 700;
}

.made-with__love {
  margin-left: 4px;
  margin-right: 4px;
}

@media screen and (max-width: 840px) {
  .footer {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
}

.info {
  padding-top: 8rem;
  display: grid;
  grid-template-columns: 256px repeat(2, 1fr);
  grid-gap: 24px;
  grid-template-areas:
    ". name name"
    "portrait bio bio";
}

.info__name {
  grid-area: name;
}

.info__portrait {
  grid-area: portrait;
  width: 100%;
  border-radius: 10%;
}

.info__bio {
  grid-area: bio;
}

@media screen and (max-width: 840px) {
  .info {
    padding-top: 2rem;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas: "portrait" "name" "bio";
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
  }

  .info__portrait {
    width: 140px;
    margin: auto;
  }

  .info__bio {
    text-align: justify;
  }
}

.main {
  display: block;
  margin: auto;
  width: 50%;
  min-width: 840px;
}

@media screen and (max-width: 840px) {
  .main {
    width: 100%;
    min-width: auto;
    padding: 24px;
  }
}

